import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { AuthenticationResult } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common/dist/account/AccountInfo';
import { Integration } from '../../../settings/integrations/get-integrations/get-integrations.service';

export const outlookSignInAction = createAction(
  ActionTypes.OUTLOOK_SIGN_IN,
  props<{ payload?: {account: AccountInfo} }>()
);

export const outlookSignInSuccessAction = createAction(
  ActionTypes.OUTLOOK_SIGN_IN_SUCCESS,
  // TODO: Add type
  props<{ authenticationResult: AuthenticationResult }>()
);

export const outlookSignInFailureAction = createAction(
  ActionTypes.OUTLOOK_SIGN_IN_FAILURE,
  // TODO: Add type
  props<{ error: any }>()
);

export const outlookSignOutAction = createAction(
  ActionTypes.OUTLOOK_SIGN_OUT,
  props<{ payload?: { integration: Integration } }>()
);

export const outlookSignOutSuccessAction = createAction(
  ActionTypes.OUTLOOK_SIGN_OUT_SUCCESS,
);

export const outlookSignOutFailureAction = createAction(
  ActionTypes.OUTLOOK_SIGN_OUT_FAILURE,
  // TODO: Add type
  props<{ error: any }>()
);

export const outlookGetAuthUserAction = createAction(
  ActionTypes.OUTLOOK_GET_AUTH_USER,
);

export const outlookGetAuthUserSuccessAction = createAction(
  ActionTypes.OUTLOOK_GET_AUTH_USER_SUCCESS,
  // TODO: Add type
  props<{ user: any }>()
);

export const outlookGetAuthUserFailureAction = createAction(
  ActionTypes.OUTLOOK_GET_AUTH_USER_FAILURE,
  // TODO: Add type
  props<{ error: any }>()
);

// TOKEN
/*export const outlookAddAuthTokenAction = createAction(
  ActionTypes.OUTLOOK_ADD_AUTH_TOKEN,
);

export const outlookAddAuthTokenSuccessAction = createAction(
  ActionTypes.OUTLOOK_ADD_AUTH_TOKEN_SUCCESS,
  // TODO: Add type
  props<{ token: string }>()
);

export const outlookAddAuthTokenFailureAction = createAction(
  ActionTypes.OUTLOOK_ADD_AUTH_TOKEN_FAILURE,
  // TODO: Add type
  props<{ error: any }>()
);

export const outlookRemoveAuthTokenAction = createAction(
  ActionTypes.OUTLOOK_REMOVE_AUTH_TOKEN,
);

export const outlookRemoveAuthTokenSuccessAction = createAction(
  ActionTypes.OUTLOOK_REMOVE_AUTH_TOKEN_SUCCESS,
  // TODO: Add type
  props<{ token: string }>()
);

export const outlookRemoveAuthTokenFailureAction = createAction(
  ActionTypes.OUTLOOK_REMOVE_AUTH_TOKEN_FAILURE,
  // TODO: Add type
  props<{ error: any }>()
);

export const outlookGetAuthTokenAction = createAction(
  ActionTypes.OUTLOOK_GET_AUTH_TOKEN,
);

export const outlookGetAuthTokenSuccessAction = createAction(
  ActionTypes.OUTLOOK_GET_AUTH_TOKEN_SUCCESS,
  // TODO: Add type
  props<{ token: string }>()
);

export const outlookGetAuthTokenFailureAction = createAction(
  ActionTypes.OUTLOOK_GET_AUTH_TOKEN_FAILURE,
  // TODO: Add type
  props<{ error: any }>()
);*/
