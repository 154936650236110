import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GetIntegrationsState } from './get-integrations.types';

const getIntegrationsFeatureSelector = createFeatureSelector<GetIntegrationsState>('getIntegrations');

export const selectGetIntegrationsLoading = createSelector(
  getIntegrationsFeatureSelector,
  state => state.loading
);

export const selectGetIntegrationsLoaded = createSelector(
  getIntegrationsFeatureSelector,
  state => state.loaded
);

export const selectGetIntegrations = createSelector(
  getIntegrationsFeatureSelector,
  state => state.integrations
);

export const selectGetIntegrationsError = createSelector(
  getIntegrationsFeatureSelector,
  state => state.error
);
