import { environment } from './environments/environment';

export const OAuthSettings = {
  appId: environment.integrationSettings.outlook.clientId,
  redirectUri: window.location.origin,
  scopes: [
    "user.read",
    "mailboxsettings.read",
    "calendars.readwrite"
  ],
};
