export enum ActionTypes {
  OUTLOOK_SIGN_IN = '[OUTLOOK AUTH] Outlook sign in',
  OUTLOOK_SIGN_IN_SUCCESS = '[OUTLOOK AUTH] Outlook sign in success',
  OUTLOOK_SIGN_IN_FAILURE = '[OUTLOOK AUTH] Outlook sign in failure',
  OUTLOOK_SIGN_OUT = '[OUTLOOK AUTH] Outlook sign out',
  OUTLOOK_SIGN_OUT_SUCCESS = '[OUTLOOK AUTH] Outlook sign out success',
  OUTLOOK_SIGN_OUT_FAILURE = '[OUTLOOK AUTH] Outlook sign out failure',
  OUTLOOK_GET_AUTH_USER = '[OUTLOOK AUTH] Outlook get auth user',
  OUTLOOK_GET_AUTH_USER_SUCCESS = '[OUTLOOK AUTH] Outlook get auth user success',
  OUTLOOK_GET_AUTH_USER_FAILURE = '[OUTLOOK AUTH] Outlook get auth user failure',

  // // TODO: REMOVE
  // OUTLOOK_ADD_AUTH_TOKEN = '[OUTLOOK AUTH] Outlook add auth token',
  // OUTLOOK_ADD_AUTH_TOKEN_SUCCESS = '[OUTLOOK AUTH] Outlook add auth token success',
  // OUTLOOK_ADD_AUTH_TOKEN_FAILURE = '[OUTLOOK AUTH] Outlook add auth token failure',
  // OUTLOOK_REMOVE_AUTH_TOKEN = '[OUTLOOK AUTH] Outlook remove auth token',
  // OUTLOOK_REMOVE_AUTH_TOKEN_SUCCESS = '[OUTLOOK AUTH] Outlook remove auth token success',
  // OUTLOOK_REMOVE_AUTH_TOKEN_FAILURE = '[OUTLOOK AUTH] Outlook remove auth token failure',
  // OUTLOOK_GET_AUTH_TOKEN = '[OUTLOOK AUTH] Outlook get auth token',
  // OUTLOOK_GET_AUTH_TOKEN_SUCCESS = '[OUTLOOK AUTH] Outlook get auth token success',
  // OUTLOOK_GET_AUTH_TOKEN_FAILURE = '[OUTLOOK AUTH] Outlook get auth token failure',
}
