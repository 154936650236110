import { Injectable } from '@angular/core';
import { OAuthSettings } from '../../../outlook-oauth';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, EMPTY, from, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { AuthenticationResult, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { catchError, filter, map, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { outlookGetAuthUserAction } from './store/outlook-auth.actions';
import { addIntegration, removeIntegration } from '../../data-access/integration';
import { Firestore } from '@angular/fire/firestore';
import { selectCurrentUserId } from '../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { selectGetIntegrations } from '../../settings/integrations/get-integrations/store/get-integrations.selectors';
import { AccountInfo } from '@azure/msal-common/dist/account/AccountInfo';
import { Integration } from '../../settings/integrations/get-integrations/get-integrations.service';

export class OutlookUser {
  displayName!: string;
  email!: string;
  avatar!: string;
  timeZone!: string;
}

@Injectable({
  providedIn: 'root'
})
export class OutlookAuthService {
  graphClient?: Client;
  user?: OutlookUser;

  authenticatedSubject = new BehaviorSubject<boolean>(false);
  authenticated$ = this.authenticatedSubject.asObservable();
  pendingReLoginSubject = new BehaviorSubject<boolean>(false);
  pendingReLogin$ = this.pendingReLoginSubject.asObservable();

  get authenticated(): boolean {
    return this.authenticatedSubject.value;
  }

  constructor(private msalService: MsalService, private store$: Store, private firestore: Firestore) {
    void this.onInit();
  }

  async onInit(): Promise<void> {
    await this.msalService.initialize();

    const outlookIntegration$ = this.store$.pipe(
      select(selectGetIntegrations),
      filter(inputIsNotNullOrUndefined),
      map(integrations => integrations.find(integration => integration.type === 'outlook')),
    );

    outlookIntegration$.subscribe(integration => {
      if (!integration && this.msalService.instance.getAllAccounts().length > 0) {
        this.authenticatedSubject.next(false);
      }
    });

    outlookIntegration$.pipe(
      filter(inputIsNotNullOrUndefined),
      switchMap(integration => {
        const authResult = JSON.parse(integration.data!) as AuthenticationResult;
        return from(
          this.msalService.acquireTokenSilent({
            account: authResult.account!,
            scopes: authResult.scopes,
          })
        ).pipe(
          catchError(() => this.msalService.acquireTokenPopup({
            account: authResult.account!,
            scopes: authResult.scopes,
          }))
        )
      })
    ).subscribe({
      next: authResult => {
        this.msalService.instance.setActiveAccount(authResult.account!);
        this.authenticatedSubject.next(true);
      },
      error: () => this.pendingReLoginSubject.next(true),
    });

    this.authenticated$.subscribe(authenticated => {
      if (authenticated) {
        const activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount) {
          throw new Error('Error: No Active Account');
        }

        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
          this.msalService.instance as PublicClientApplication,
          {
            account: activeAccount,
            scopes: OAuthSettings.scopes,
            interactionType: InteractionType.Silent,
          }
        );

        this.graphClient = Client.initWithMiddleware({
          authProvider,
        });
        this.store$.dispatch(outlookGetAuthUserAction());
        return;
      }
      this.msalService.instance.setActiveAccount(null);
      this.graphClient = undefined;
    });
  }

  signIn(params?: {account?: AccountInfo}): Observable<AuthenticationResult> {
    const login$ = this.msalService.acquireTokenPopup({
      ...OAuthSettings,
      ...params,
    });

    const s$ = params?.account ? login$ : login$.pipe(
      switchMap(authenticationResult =>
        this.addIntegration(authenticationResult).pipe(
          map(() => authenticationResult),
        ),
      ),
      shareReplay(1),
      take(1),
    );

    s$.pipe(take(1)).subscribe(authenticationResult => {
      this.msalService.instance.setActiveAccount(authenticationResult.account);
      this.pendingReLoginSubject.next(false);
      this.authenticatedSubject.next(true);
    });

    return s$;
  }

  signOut(integration?: Integration): Observable<[void, ...void[]]> {
    const logout$ = this.msalService.logoutPopup().pipe(
      switchMap(() => integration ? this.removeIntegration(integration) : EMPTY),
      shareReplay(1),
    );

    logout$.pipe(take(1)).subscribe(() => {
      this.authenticatedSubject.next(false);
    });

    return logout$;
  }

  getUser(): Observable<OutlookUser | undefined> {
    return this.authenticated$.pipe(
      filter(authenticated => authenticated),
      switchMap(() =>
        this.graphClient ? this.graphClient
          .api('/me')
          .select('displayName,mail,mailboxSettings,userPrincipalName')
          .get() : EMPTY
      ),
      take(1),
      map(graphUser => {
        const user = new OutlookUser();
        user.displayName = graphUser.displayName ?? '';
        // Prefer the mail property, but fall back to userPrincipalName
        user.email = graphUser.mail ?? graphUser.userPrincipalName ?? '';
        user.timeZone = graphUser.mailboxSettings?.timeZone ?? 'UTC';

        // Use default avatar
        user.avatar = '/assets/no-profile-photo.png';

        return user;
      }),
    );
  }

  addIntegration(data: AuthenticationResult) {
    return this.store$.pipe(
      select(selectCurrentUserId),
      filter(inputIsNotNullOrUndefined),
      switchMap(currentUserId =>
        addIntegration(this.firestore, currentUserId, 'own', {
          type: 'outlook',
          data: JSON.stringify(data),
        })
      ),
      take(1),
    );
  }

  removeIntegration(integration: Integration): Observable<[void, ...void[]]> {
    return this.store$.pipe(
      select(selectCurrentUserId),
      filter(inputIsNotNullOrUndefined),
      switchMap(currentUserId =>
        removeIntegration(this.firestore, currentUserId, 'own', integration)
      ),
      take(1),
    );
  }
}
